<template>
  <div>
    <formPreview :form="data.form" :preview="preview" />
  </div>
</template>
<script>
export default {
  components: {
    formPreview () { return import('@/components/formBuilder/preview') }
  },
  props: { data: Object, preview: Boolean },
  data () {
    return {
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
